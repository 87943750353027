import React from "react";
import mountains from "../assets/images/abdulla-dhahri-lnpopksgaXs-unsplash/abdulla-dhahri-lnpopksgaXs-unsplash.jpg";
import rectangle from "../assets/images/Group 18960/Group 18960.png";
import logo from "../assets/images/srenalogopng-ar-en-red/srenalogopng-ar-en-red.png";
import questionIcon from "../assets/images/Icon awesome-question.svg";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import Styles from "../styles/WhyUs.module.css";

const WhyUs = () => {
  return (
    <main className="why-srena">
      <Container fluid className="">
        <Row className={`${Styles.row} flex-column-reverse flex-xxl-row`}>
          <Col xs={1} className="d-none d-xxl-block"></Col>
          <Col
            xs={12}
            xxl={5}
            className={`${Styles.col} position-relative d-flex justify-content-center justify-content-xxl-end`}
          >
            <Image
              rounded
              // fluid
              className={`${Styles.mountains} col-left-image w-100`}
              src={mountains}
              alt="A road between mountains"
            />
            {/* <Image
              className={`${Styles.rectangle_layer} rectangle-layer position-absolute`}
              src={rectangle}
              alt="A rectangle layer"
            /> */}
          </Col>
          <Col xs={12} xxl={6} className="content p-0">
            <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-3 text-nowrap p-4">
              <div>
                <Image className={Styles.logo} src={logo} alt="Srena logo" />
              </div>
              <h3 className={Styles.title}>Smart internal Transportation</h3>
            </div>
            <p
              className={`${Styles.p} p-4 pt-1 py-lg-4`}
              style={{ fontSize: "25px" }}
            >
              Internal transportation in{" "}
              <span style={{ fontFamily: "Hacen Algeria Bd" }}>
                Saudi Arabia
              </span>{" "}
              is untouched market and is only running in a very antiquated
              traditional ways. Adding to that the Parking limitations and
              congestions.
            </p>
            <div
              className="mb-4 p-4"
              style={{ background: "var(--light-pink-color)" }}
            >
              <div className="d-flex mb-2">
                <h3
                  className={`${Styles.h3} text-capitalize`}
                  style={{ fontFamily: "Hacen Algeria Bd" }}
                >
                  why srena
                </h3>
                <Image
                  className={Styles.question_icon}
                  src={questionIcon}
                  alt="Question icon"
                />
              </div>
              <div>
                <ul className={Styles.ul}>
                  <li className={Styles.li}>
                    Fully integrated set of apps to cater for a “Super App”
                  </li>
                  <li className={Styles.li}>
                    (Ticketing, Taxi, Deliver, Share, Parking, Service and more)
                  </li>
                  <li className={Styles.li}>
                    Customizable per zones (Vehicles, services, business model..
                    etc.)
                  </li>
                  <li className={Styles.li}>
                    Strong experience in internal transportation services
                  </li>
                  <li className={Styles.li}>Vast experience in EVs</li>
                  <li className={Styles.li}>
                    IoT devices, internet connected, builds connected vehicle
                    mesh
                  </li>
                  <li className={Styles.li}>
                    AI powered cameras to analyze drivers & riders & control
                    actions
                  </li>
                  <li className={Styles.li}>
                    En-route towards autonomous golf carts
                  </li>
                  <li className={Styles.li}>
                    Smart city and Micromobility Infrastructure ready
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={5}
            className={`${Styles.wrapper_stack} d-flex justify-content-end p-0`}
          >
            <Stack direction="horizontal" className="justify-content-end">
              <Image
                className={`${Styles.rectangle_layer} rectangle-layer `}
                src={rectangle}
                alt="A rectangle layer"
              />
            </Stack>
          </Col>
          <Col xs={7}></Col>
        </Row>
      </Container>
    </main>
  );
};

export default WhyUs;
