import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import car from "../assets/images/car (4)/car (4).png";
import arrowRight from "../assets/images/Group 18929.svg";
import delivery from "../assets/images/food-delivery/food-delivery.png";
import scooter from "../assets/images/kick-scooter (1)/kick-scooter (1).png";
import electricCar from "../assets/images/electric-car (1)/electric-car (1).png";
import managementIcon from "../assets/images/product-management/product-management.png";
import bike from "../assets/images/bike (2)/bike (2).png";
import golfCart from "../assets/images/golf-cart (1)/golf-cart (1).png";
import carWash from "../assets/images/car-wash (1)/car-wash (1).png";
import parkedCar from "../assets/images/parked-car/parked-car.png";
import bookIcon from "../assets/images/Group 18977/Group 18977.png";
import trackIcon from "../assets/images/Group 18976/Group 18976.png";
import manageIcon from "../assets/images/Group 18975/Group 18975.png";
import pinkBackground from "../assets/images/Path 35109/Path 35109.png";
import Styles from "../styles/Services.module.css";

const Services = () => {
  return (
    <section className="services position-relative pb-5 mb-2">
      <Container className={`${Styles.container} px-sm-0 px-md-5`}>
        <Row>
          <Col>
            <h3 className={Styles.h3}>srena services</h3>
          </Col>
        </Row>
        <Row className="boxes justify-content-between justify-content-sm-center justify-content-lg-between row-gap-5 column-gap-3 px-md-3 px-lg-5">
          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={car}
                alt="VIP internal transportation service"
              />
            </div>
            <p className={Styles.p}>VIP internal transportation service</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={delivery}
                alt="Food Delivery Service"
              />
            </div>
            <p className={Styles.p}>Food Delivery Service</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={scooter}
                alt="Mobility Scooter Sharing"
              />
            </div>
            <p className={Styles.p}>Mobility Scooter Sharing</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={electricCar}
                alt="LSEV low speed electric vehicles sharing services"
              />
            </div>
            <p className={Styles.p}>
              LSEV low speed electric vehicles sharing services
            </p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={managementIcon}
                alt="Delivery Service"
              />
            </div>
            <p className={Styles.p}>Delivery Service</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={bike}
                alt="E- Scooters & E- Bike services"
              />
            </div>
            <p className={Styles.p}>E- Scooters & E- Bike services</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={golfCart}
                alt="Request Golf Cart"
              />
            </div>
            <p className={Styles.p}>Request Golf Cart</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={carWash}
                alt="Request Car wash"
              />
            </div>
            <p className={Styles.p}>Request Car wash</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>

          <Col
            xs={6}
            sm={4}
            className={`${Styles.col} box bg-white d-flex justify-content-center align-items-center rounded position-relative z-1`}
          >
            <div className={Styles.absolute}>
              <Image
                className={`${Styles.icon} position-relative`}
                src={parkedCar}
                alt="Request Valet Parking"
              />
            </div>
            <p className={Styles.p}>Request Valet Parking</p>
            <div
              className={`${Styles.details} position-absolute bottom-0 end-0 px-2 d-flex align-items-center gap-2`}
            >
              <p className="m-0">Details</p>
              <Image src={arrowRight} alt="Arrow right to see details" />
            </div>
          </Col>
        </Row>
      </Container>

      <Row className={`${Styles.row2} gap-1 gap-lg-5 justify-content-center`}>
        <Col sx={4} className="">
          <div className="relative-box">
            <Image
              className={Styles.services_icons}
              src={bookIcon}
              alt="Book icon"
            />
          </div>
          <h4 className={Styles.h4}>Book</h4>
          <p className={Styles.p2}>
            Book your transportation needs with just a few clicks
          </p>
        </Col>
        <Col sx={4} className="">
          <div className="relative-box">
            <Image
              className={Styles.services_icons}
              src={trackIcon}
              alt="Track icon"
            />
          </div>
          <h4 className={Styles.h4}>Track</h4>
          <p className={Styles.p2}>
            Track your transportation needs with just a few clicks
          </p>
        </Col>
        <Col sx={4} className="">
          <div className="relative-box">
            <Image
              className={Styles.services_icons}
              src={manageIcon}
              alt="Manage icon"
            />
          </div>
          <h4 className={Styles.h4}>Manage</h4>
          <p className={Styles.p2}>
            manage your transportation needs with just a few clicks
          </p>
        </Col>
      </Row>

      <Image
        className={`${Styles.bg_pink} position-absolute bottom-0 w-100`}
        src={pinkBackground}
        alt="Pink background"
      />
    </section>
  );
};

export default Services;
