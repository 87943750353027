import React, { useRef, useState } from "react";
import { Carousel, Col, Container, Image, Row } from "react-bootstrap";
import srenaLogo from "../assets/images/Srena/Srena.png";
import rightArrow from "../assets/images/Icon ionic-ios-arrow-round-forward (1).svg";
import leftArrow from "../assets/images/Icon ionic-ios-arrow-round-forward.svg";
import leftQuote from "../assets/images/left-quote/left-quote.png";
import person from "../assets/images/Mask Group 15/Mask Group 15.jpg";
import Styles from "../styles/OurClients.module.css";

const OurClients = () => {
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);

  const handleSelect1 = (selectedIndex, e) => {
    setIndex1(selectedIndex);
  };

  const handleSelect2 = (selectedIndex, e) => {
    setIndex2(selectedIndex);
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const onNextClick1 = () => {
    ref1.current.next();
  };

  const onPrevClick2 = () => {
    ref2.current.prev();
  };
  const onNextClick2 = () => {
    ref2.current.next();
  };

  return (
    <section className="clients">
      <Container className="mb-4">
        <Row>
          <Col>
            <h4 className={Styles.title}>Our Clients</h4>
          </Col>
        </Row>
        <Row className="justify-content-center gap-4 mt-5">
          <Col xs={12}>
            <Carousel
              activeIndex={index1}
              onSelect={handleSelect1}
              ref={ref1}
              // interval --> prevent carousel from toggling auto
              // interval={null}
            >
              <Carousel.Item>
                <Row className="px-5">
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="px-5">
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="px-5">
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                  <Col xs={4}>
                    <Image
                      className={Styles.client_image}
                      src={srenaLogo}
                      alt="Srena logo"
                    />
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>

            <div className="slides-control text-end position-relative bottom-50">
              <Image
                className={Styles.control_icon}
                onClick={onNextClick1}
                src={rightArrow}
                alt="Right arrow"
              />
            </div>
          </Col>

          <Col xs={12}>
            <Carousel
              activeIndex={index2}
              onSelect={handleSelect2}
              ref={ref2}
              // interval --> prevent carousel from toggling auto
              // interval={null}
            >
              <Carousel.Item>
                <Row className="mySlides2-container justify-content-center">
                  <Col
                    xs={12}
                    md={3}
                    className="col-left d-flex flex-column align-items-center"
                  >
                    <div>
                      <Image src={person} alt="CEO photo" />
                    </div>
                    <div className="text-center mt-3">
                      <h4 className={Styles.h4}>Ahmed ajami</h4>
                      <p className={Styles.p1}>Ceo company name</p>
                    </div>
                  </Col>
                  <Col md={7} className="col-right">
                    <Image
                      className="left-quote"
                      src={leftQuote}
                      alt="Left quote"
                    />
                    <div className="px-4">
                      <p className={Styles.p2}>
                        Internal transportation in{" "}
                        <span style={{ fontFamily: "Hacen Algeria Bd" }}>
                          Saudi Arabia
                        </span>{" "}
                        is untouched market and is only running in a very
                        antiquated traditional ways. Adding to that the Parking
                        limitations and congestions.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="mySlides2-container justify-content-center">
                  <Col
                    xs={12}
                    md={3}
                    className="col-left d-flex flex-column align-items-center"
                  >
                    <div>
                      <Image src={person} alt="CEO photo" />
                    </div>
                    <div className="text-center mt-3">
                      <h4 className={Styles.h4}>Mohamed ajami</h4>
                      <p className={Styles.p1}>Ceo company name</p>
                    </div>
                  </Col>
                  <Col md={7} className="col-right">
                    <Image
                      className="left-quote"
                      src={leftQuote}
                      alt="Left quote"
                    />
                    <div className="px-4">
                      <p className={Styles.p2}>
                        Internal transportation in{" "}
                        <span style={{ fontFamily: "Hacen Algeria Bd" }}>
                          Saudi Arabia
                        </span>{" "}
                        is untouched market and is only running in a very
                        antiquated traditional ways. Adding to that the Parking
                        limitations and congestions.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row className="mySlides2-container justify-content-center">
                  <Col
                    xs={12}
                    md={3}
                    className="col-left d-flex flex-column align-items-center"
                  >
                    <div>
                      <Image src={person} alt="CEO photo" />
                    </div>
                    <div className="text-center mt-3">
                      <h4 className={Styles.h4}>Anas ajami</h4>
                      <p className={Styles.p1}>Ceo company name</p>
                    </div>
                  </Col>
                  <Col md={7} className="col-right">
                    <Image
                      className="left-quote"
                      src={leftQuote}
                      alt="Left quote"
                    />
                    <div className="px-4">
                      <p className={Styles.p2}>
                        Internal transportation in{" "}
                        <span style={{ fontFamily: "Hacen Algeria Bd" }}>
                          Saudi Arabia
                        </span>{" "}
                        is untouched market and is only running in a very
                        antiquated traditional ways. Adding to that the Parking
                        limitations and congestions.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>

            <div className="slides-control d-flex align-items-center gap-3 justify-content-end">
              <Image
                className={Styles.control_icon}
                onClick={onPrevClick2}
                src={leftArrow}
                alt="Left arrow"
              />
              <div className={Styles.number_text}>
                <span className={Styles.span}>{index2 + 1}</span> / 3
              </div>
              <Image
                className={Styles.control_icon}
                onClick={onNextClick2}
                src={rightArrow}
                alt="Right arrow"
              />
            </div>
          </Col>
        </Row>
      </Container>

      <div
        className="d-flex justify-content-center align-items-center text-center text-md-start py-4"
        style={{ background: "#fcf3f4" }}
      >
        <Row className="justify-content-between align-items-center gap-3">
          <Col xs={12} md={8} className="content">
            <div>
              <h5 className={Styles.h5}>Join Our Drivers</h5>
              <p className={Styles.p3}>Join & enjoy many benefits with Srena</p>
            </div>
          </Col>
          <Col xs={12} md={3} className="d-flex justify-content-center">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className={`${Styles.button} d-flex justify-content-center align-items-center text-nowrap gap-2 text-white py-3 px-4 text-capitalize`}
              title="Join Now"
            >
              Join Now
            </a>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default OurClients;
