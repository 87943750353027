import React, { useState } from "react";
import { Image } from "react-bootstrap";
import arrowUp from "../assets/images/Icon ionic-ios-arrow-round-forward/Icon ionic-ios-arrow-round-forward.png";
import Styles from "../styles/ToTop.module.css";

const ToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      onClick={scrollToTop}
      id="ToTop"
      className={`${
        Styles.container
      } d-flex flex-column align-items-center position-fixed  bg-transparent ${
        visible ? "d-flex" : "d-none"
      }`}
    >
      <Image className={Styles.arrow} src={arrowUp} alt="Arrow up" />
      <div className={Styles.top} title="Go to top">
        Top
      </div>
    </div>
  );
};

export default ToTop;
