import React from "react";
import { Col, Container, Image, Row, Stack } from "react-bootstrap";
import callIcon from "../assets/images/Icon zocial-call.svg";
import mailIcon from "../assets/images/Icon ionic-md-mail.svg";
import locationIcon from "../assets/images/Icon metro-location.svg";
import linkedIn from "../assets/images/Group 18962.svg";
import instagram from "../assets/images/Group 18963.svg";
import faceBook from "../assets/images/Group 18964.svg";
import twitter from "../assets/images/Group 18965.svg";
import Styles from "../styles/Footer.module.css";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row className={" text-white align-items-stretch"}>
          <Col xs={12} md={6} lg={5} className={`${Styles.col} px-3 pt-5 pb-4`}>
            <h6 className={Styles.h6_1}>Get In Touch</h6>
            <p className={`${Styles.p1} my-4 px-3`}>
              We are available 24h in 7 days at the week
            </p>
            <div className="contacts d-flex flex-column gap-3 px-5 mb-4">
              <div className="d-flex align-items-center gap-3">
                <Image
                  className={Styles.icon}
                  src={callIcon}
                  alt="phone icon"
                />
                <p className={Styles.p2}>0100000000</p>
              </div>
              <div className="d-flex align-items-center gap-3">
                <Image
                  className={Styles.icon}
                  src={mailIcon}
                  alt="email icon"
                />
                <p className={Styles.p2}>Srena.com</p>
              </div>
              <div className="d-flex align-items-center gap-3">
                <Image
                  className={Styles.icon}
                  src={locationIcon}
                  alt="location icon"
                />
                <p className={Styles.p2}>Saudi arabia</p>
              </div>
            </div>
            <div className="px-5">
              <p className={Styles.p3}>Follow Us</p>
              <div className="social-icons px-2 mt-2 d-flex gap-2">
                <a
                  href="https://www.linkedin.com/company/srenaapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="LinkedIn"
                >
                  <Image
                    className={Styles.social_icon}
                    src={linkedIn}
                    alt="LinkedIn"
                  />
                </a>
                <a
                  href="https://www.instagram.com/SrenaApp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <Image
                    className={Styles.social_icon}
                    src={instagram}
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.facebook.com/SrenaApp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="FaceBook"
                >
                  <Image
                    className={Styles.social_icon}
                    src={faceBook}
                    alt="FaceBook"
                  />
                </a>
                <a
                  href="https://twitter.com/SrenaApp"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Twitter"
                >
                  <Image
                    className={Styles.social_icon}
                    src={twitter}
                    alt="Twitter"
                  />
                </a>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={7} className="h-100 position-relative px-0">
            <div className={Styles.image_container}></div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={5}></Col>
          <Col xs={12} md={6} lg={7} className={Styles.wrapper_stack}>
            <Stack direction="horizontal" className="justify-content-center">
              <form className={`${Styles.form}`}>
                <div className="px-3 text-white">
                  <h6 className={Styles.h6_2}>Send Message</h6>
                </div>
                <Row className="justify-content-center">
                  <div className="px-5">
                    <Col xs={12}>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className={Styles.input}
                      />
                    </Col>
                  </div>
                  <div className="px-5">
                    <Col xs={12}>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        required
                        className={Styles.input}
                      />
                    </Col>
                  </div>
                  <div className="px-5">
                    <Col xs={12}>
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        rows="5"
                        required
                        className={Styles.input}
                      />
                    </Col>
                  </div>
                  <div className="px-5">
                    <Col xs={12} className="mt-3 text-center">
                      <button className={Styles.button} type="submit">
                        Send
                      </button>
                    </Col>
                  </div>
                </Row>
              </form>
            </Stack>
          </Col>
        </Row>
        <Row className="mt-3 px-4 text-nowrap">
          <Col>
            <Row className="privacy d-flex">
              <Col>
                <p className={Styles.p4}>
                  @<span>20223</span> Srena
                </p>
              </Col>
              <Col className="d-flex gap-2 justify-content-end">
                <p className={Styles.p4}>Privacy Policy</p>
                <p className={Styles.p4}>Terms&Conditions</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
