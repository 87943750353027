import Footer from "./components/Footer";
import Header from "./components/Header";
import Hero from "./components/Hero";
import OurClients from "./components/OurClients";
import OurSolution from "./components/OurSolution";
import Services from "./components/Services";
import ToTop from "./components/ToTop";
import WhyUs from "./components/WhyUs";

function App() {
  return (
    <div className="position-relative">
      <Header />
      <Hero />
      <WhyUs />
      <Services />
      <OurSolution />
      <OurClients />
      <Footer />
      <ToTop />
    </div>
  );
}

export default App;
