import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import fileIcon from "../assets/images/Icon awesome-file.svg";
import mockups from "../assets/images/Flying-iPhone-X-Mockups/Flying-iPhone-X-Mockups.png";
import mark from "../assets/images/warning-sign/warning-sign.png";
import rectangleLayer from "../assets/images/Rectangle 10250/Rectangle 10250.png";
import Styles from "../styles/OurSolution.module.css";

const OurSolution = () => {
  return (
    <section className="our-solution">
      <Container>
        <Row className="p-4 p-lg-5 gap-5 gap-xl-0 justify-content-xl-between">
          <Col xs={12} xl={5} className="solution-container">
            <div className="max-w-522">
              <h4 className={Styles.h4}>Our Solution</h4>
              <p className={Styles.p}>
                Our solution is tailored to your specific needs, whether you're
                transporting goods, employees or clients.
              </p>
            </div>
            <ul className={Styles.ul}>
              <li className={Styles.li}>Reporting</li>
              <li className={Styles.li}>Customer Panel Step By Step Flow</li>
              <li className={Styles.li}>Driver Console Step By Step Flow</li>
              <li className={Styles.li}>
                Dispatcher Console Flow (Renting employee)
              </li>
              <li className={Styles.li}>Delivery Service</li>
              <li className={Styles.li}>Admin Panel Step By Step Flow</li>
            </ul>
            <div className="solution-wrapper">
              <p className={Styles.p2}>
                To more details , You can view the company's profile with all
                services from here
              </p>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
                className={`${Styles.button} d-flex justify-content-center align-items-center text-nowrap gap-2 text-white py-3 px-4 text-capitalize`}
                title="Company profile"
              >
                <Image
                  className={Styles.file_icon}
                  src={fileIcon}
                  alt="File icon"
                />
                <span className={Styles.span}>company profile</span>
              </a>
            </div>
          </Col>
          <Col
            xs={12}
            xl={7}
            className="d-flex justify-content-center justify-content-sm-end justify-content-xl-center position-relative"
          >
            <Image
              className={Styles.mockups}
              src={mockups}
              alt="Flying iphone x mockups"
            />
            <Image
              className={Styles.rectangle}
              src={rectangleLayer}
              alt="Rectangle layer"
            />
          </Col>
        </Row>
      </Container>

      <aside className={`${Styles.aside} my-5 text-white mx-auto`}>
        <Row className={`${Styles.row3} position-relative ps-2 py-5`}>
          <Col xs={12} className="d-flex align-items-end ps-4">
            <h4 className={Styles.h4_3}>Let's meet</h4>
            <Image className={Styles.mark} src={mark} alt="Exclamation mark" />
          </Col>
          <Col xs={12} md={6} className="ps-4 pe-0">
            <p className={Styles.p_3}>
              We want to help you ! , so let's meet to explain to you what we
              have and know what suits you and implement it together
            </p>
          </Col>
        </Row>
      </aside>
    </section>
  );
};

export default OurSolution;
